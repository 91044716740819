// EventSessionStyles/index.tsx
import { Box, Button, Collapse, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "./styles";
import { useUser } from "../../providers/users";
import WaitingRoomStyles from "./WaitingRoomStyles";
import DirectLinkStyles from "./DirectLinkStyles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IEventSession } from "../../providers/eventSessions/eventsSessions.interface";

interface IProps {
  eventSession: IEventSession | null;
  setEventSession: React.Dispatch<React.SetStateAction<IEventSession | null>>;
  handleComplete: () => void;
  handleBack: () => void;
}

const EventSessionStyles = ({
  eventSession,
  setEventSession,
  handleComplete,
  handleBack,
}: IProps) => {
  const { t } = useTranslation();
  const { apiLoading } = useUser();
  const [openWaitingRoom, setOpenWaitingRoom] = useState(true);
  const [openDirectLink, setOpenDirectLink] = useState(false);

  return (
    <Container>
      <div
        className="section-header"
        onClick={() => setOpenWaitingRoom(!openWaitingRoom)}
        style={{ cursor: "pointer" }}
      >
        <h3>Waiting Room Styles Configuration</h3>
        <div className="icon-wrapper">
          {openWaitingRoom ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
      </div>
      <Collapse in={openWaitingRoom}>
        <WaitingRoomStyles
          eventSession={eventSession}
          setEventSession={setEventSession}
        />
      </Collapse>

      <div
        className="section-header"
        onClick={() => setOpenDirectLink(!openDirectLink)}
        style={{ cursor: "pointer" }}
      >
        <h3>Direct Link Page Styles</h3>
        <div className="icon-wrapper">
          {openDirectLink ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
      </div>
      <Collapse in={openDirectLink}>
        <DirectLinkStyles
          eventSession={eventSession}
          setEventSession={setEventSession}
        />
      </Collapse>

      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          color="inherit"
          onClick={handleBack}
          disabled={apiLoading}
          sx={{ mr: 1, borderRadius: "25px" }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={handleComplete}
          variant="contained"
          disabled={apiLoading}
          sx={{ mr: 1, borderRadius: "25px" }}
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};

export default EventSessionStyles;
