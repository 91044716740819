import styled from "styled-components";

export const Container = styled.div`
  /* max-width: 80vw; */
  margin-top: 50px;
  background-color: #fafafa;
  border-radius: 2px;
  box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }
  form > label {
    margin-top: 10px;
  }

  .inputNone {
    opacity: 0;
    width: 1px;
    /* float: right; */
    /* pointer-events: none; */
  }
  .scheduleDiv {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .liveContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .liveContainer > div {
    flex: 1;
  }
  .liveContentContainer {
    border: 1px solid gray;
    height: 250px;
    overflow-y: scroll;
    max-width: 500px;
    margin-top: 16px;
  }
  .liveContent {
    /* background-color: #ffff; */
    padding: 8px;
    justify-content: space-between;
    margin: 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    box-shadow: 2px 0px 10px rgba(3, 3, 3, 0.1);
  }
  .liveContent > div > p {
    display: flex;
    gap: 5px;
    font-size: 15px;
    text-transform: capitalize;
    align-items: center;
  }
  .liveContentActions > svg:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  h5 {
    font-size: 18px;
    display: flex;
    gap: 5px;
    text-transform: capitalize;
    align-items: center;
  }
  h5 > svg,
  .liveContent > div > svg {
    width: 25px;
    height: 25px;
  }
  .shortTerms {
    margin-left: 30px;
  }
  .chatSettings {
    display: flex;
  }
  .videoPlaySettings {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .videoPlaySettings > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .advancedSettings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    width: 50%;
  }
  .advancedSettings > div > div {
    margin-top: 10px;
  }
  .errosForm {
    color: #d32f2f;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
`;
