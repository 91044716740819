import { MdOutlineCopyright } from "react-icons/md";
import { Container } from "./styles";
const Footer = () => {
  return (
    <Container>
      <p>
        <MdOutlineCopyright /> {new Date().getFullYear()} EventsPro Ai All
        Rights Reserved.
      </p>
      <p>
        <a href="">Terms of service </a> <a href=""> Privacy Policy</a>
      </p>
    </Container>
  );
};

export default Footer;
