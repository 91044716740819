// EventSessionStyles/styles.tsx
import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }

    h3 {
      margin: 0;
      user-select: none;
    }

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  h4 {
    margin: 20px 0 10px 0;
  }

  .errosForm {
    color: #d32f2f;
    font-size: 0.75rem;
    margin-top: 3px;
  }

  .inputNone {
    opacity: 0;
    width: 1px;
  }
`;

export const ColorPickerWrapper = styled.div`
  position: relative;
  .color-swatch {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
  }

  .popover {
    position: absolute;
    z-index: 2;
  }

  .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const ColorSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;

  .color-picker-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
