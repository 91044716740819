// EventSessionStyles/DirectLinkStyles.tsx
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useEventSession } from "../../providers/eventSessions";
import { useUser } from "../../providers/users";
import { ColorPickerWrapper, ColorSection } from "./styles";

interface DirectLinkProps {
  eventSession: any;
  setEventSession: (session: any) => void;
}

const DirectLinkStyles: React.FC<DirectLinkProps> = ({
  eventSession,
  setEventSession,
}) => {
  const { editEventSession } = useEventSession();
  const { apiLoading } = useUser();
  // Colors
  const [backgroundColor, setBackgroundColor] = useState("#fafafa");
  const [cardBackground, setCardBackground] = useState("#ffffff");
  const [titleColor, setTitleColor] = useState("#000000");
  const [subtitleColor, setSubtitleColor] = useState("#666666");
  const [buttonBackground, setButtonBackground] = useState("#000000");
  const [buttonTextColor, setButtonTextColor] = useState("#ffffff");
  const [buttonHoverBackground, setButtonHoverBackground] = useState("#333333");
  const [inputBackground, setInputBackground] = useState("#ffffff");
  const [inputTextColor, setInputTextColor] = useState("#000000");
  const [inputBorderColor, setInputBorderColor] = useState("#e0e0e0");
  const [inputLabelColor, setInputLabelColor] = useState("#666666");
  const [errorColor, setErrorColor] = useState("#d32f2f");

  // Font sizes and spacing
  const [titleSize, setTitleSize] = useState("24px");
  const [subtitleSize, setSubtitleSize] = useState("16px");
  const [cardPadding, setCardPadding] = useState("40px");
  const [cardBorderRadius, setCardBorderRadius] = useState("12px");
  const [cardShadow, setCardShadow] = useState(
    "0 2px 12px rgba(0, 0, 0, 0.08)"
  );
  const [formGap, setFormGap] = useState("24px");

  // Color picker display states
  const [displayColorPicker, setDisplayColorPicker] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (eventSession?.directLinkPage?.styles) {
      const styles = eventSession.directLinkPage.styles;
      setBackgroundColor(styles.backgroundColor || backgroundColor);
      setCardBackground(styles.cardBackground || cardBackground);
      setTitleColor(styles.titleColor || titleColor);
      setSubtitleColor(styles.subtitleColor || subtitleColor);
      setButtonBackground(styles.buttonBackground || buttonBackground);
      setButtonTextColor(styles.buttonTextColor || buttonTextColor);
      setButtonHoverBackground(
        styles.buttonHoverBackground || buttonHoverBackground
      );
      setInputBackground(styles.inputBackground || inputBackground);
      setInputTextColor(styles.inputTextColor || inputTextColor);
      setInputBorderColor(styles.inputBorderColor || inputBorderColor);
      setInputLabelColor(styles.inputLabelColor || inputLabelColor);
      setErrorColor(styles.errorColor || errorColor);
      setTitleSize(styles.titleSize || titleSize);
      setSubtitleSize(styles.subtitleSize || subtitleSize);
      setCardPadding(styles.cardPadding || cardPadding);
      setCardBorderRadius(styles.cardBorderRadius || cardBorderRadius);
      setCardShadow(styles.cardShadow || cardShadow);
      setFormGap(styles.formGap || formGap);
    }
  }, [eventSession]);

  const formSchema = yup
    .object()
    .shape({
      nameLabel: yup.string(),
      emailLabel: yup.string(),
      phoneLabel: yup.string(),
      buttonText: yup.string(),
      loadingText: yup.string(),
      title: yup.string(),
    })
    .required();

  type FormValues = yup.InferType<typeof formSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });

  const handleColorClick = (colorType: string) => {
    setDisplayColorPicker((prev) => ({
      ...prev,
      [colorType]: !prev[colorType],
    }));
  };

  const handleColorClose = (colorType: string) => {
    setDisplayColorPicker((prev) => ({
      ...prev,
      [colorType]: false,
    }));
  };

  const onSave = async (data: FormValues) => {
    try {
      const directLinkConfig = {
        styles: {
          backgroundColor,
          cardBackground,
          titleColor,
          subtitleColor,
          buttonBackground,
          buttonTextColor,
          buttonHoverBackground,
          inputBackground,
          inputTextColor,
          inputBorderColor,
          inputLabelColor,
          errorColor,
          titleSize,
          subtitleSize,
          cardPadding,
          cardBorderRadius,
          cardShadow,
          formGap,
        },
        labels: {
          ...data,
        },
      };

      const response = await editEventSession(eventSession.id, {
        directLinkPage: directLinkConfig,
      });

      setEventSession(response);
      toast.success("Direct link styles saved successfully");
    } catch (error) {
      toast.error("Failed to save direct link styles");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSave)} sx={{ mt: 2 }}>
      <section className="basic-info-section">
        <h4>Labels</h4>
        <TextField
          error={!!errors.title}
          helperText={errors.title?.message?.toString()}
          label="Page Title"
          variant="filled"
          defaultValue={
            eventSession?.directLinkPage?.labels?.title || "Registration"
          }
          sx={{ mr: 2, mb: 2 }}
          {...register("title")}
        />
        <TextField
          error={!!errors.nameLabel}
          helperText={errors.nameLabel?.message?.toString()}
          label="Name Label"
          variant="filled"
          defaultValue={
            eventSession?.directLinkPage?.labels?.nameLabel || "Name"
          }
          sx={{ mr: 2, mb: 2 }}
          {...register("nameLabel")}
        />
        <TextField
          error={!!errors.emailLabel}
          helperText={errors.emailLabel?.message?.toString()}
          label="Email Label"
          variant="filled"
          defaultValue={
            eventSession?.directLinkPage?.labels?.emailLabel || "Email"
          }
          sx={{ mr: 2, mb: 2 }}
          {...register("emailLabel")}
        />
        <TextField
          error={!!errors.phoneLabel}
          helperText={errors.phoneLabel?.message?.toString()}
          label="Phone Label"
          variant="filled"
          defaultValue={
            eventSession?.directLinkPage?.labels?.phoneLabel || "Phone"
          }
          sx={{ mr: 2, mb: 2 }}
          {...register("phoneLabel")}
        />
        <TextField
          error={!!errors.buttonText}
          helperText={errors.buttonText?.message?.toString()}
          label="Button Text"
          variant="filled"
          defaultValue={
            eventSession?.directLinkPage?.labels?.buttonText || "Join Event"
          }
          sx={{ mr: 2, mb: 2 }}
          {...register("buttonText")}
        />
        <TextField
          error={!!errors.loadingText}
          helperText={errors.loadingText?.message?.toString()}
          label="Loading Text"
          variant="filled"
          defaultValue={
            eventSession?.directLinkPage?.labels?.loadingText ||
            "Registering..."
          }
          sx={{ mr: 2, mb: 2 }}
          {...register("loadingText")}
        />
      </section>

      <section className="color-settings-section">
        <h4>Color Settings</h4>
        <ColorSection>
          {[
            {
              label: "Background Color",
              state: backgroundColor,
              setter: setBackgroundColor,
              key: "backgroundColor",
            },
            {
              label: "Card Background",
              state: cardBackground,
              setter: setCardBackground,
              key: "cardBackground",
            },
            {
              label: "Title Color",
              state: titleColor,
              setter: setTitleColor,
              key: "titleColor",
            },
            {
              label: "Subtitle Color",
              state: subtitleColor,
              setter: setSubtitleColor,
              key: "subtitleColor",
            },
            {
              label: "Button Background",
              state: buttonBackground,
              setter: setButtonBackground,
              key: "buttonBackground",
            },
            {
              label: "Button Text Color",
              state: buttonTextColor,
              setter: setButtonTextColor,
              key: "buttonTextColor",
            },
            {
              label: "Button Hover Background",
              state: buttonHoverBackground,
              setter: setButtonHoverBackground,
              key: "buttonHoverBackground",
            },
            {
              label: "Input Background",
              state: inputBackground,
              setter: setInputBackground,
              key: "inputBackground",
            },
            {
              label: "Input Text Color",
              state: inputTextColor,
              setter: setInputTextColor,
              key: "inputTextColor",
            },
            {
              label: "Input Border Color",
              state: inputBorderColor,
              setter: setInputBorderColor,
              key: "inputBorderColor",
            },
            {
              label: "Input Label Color",
              state: inputLabelColor,
              setter: setInputLabelColor,
              key: "inputLabelColor",
            },
            {
              label: "Error Color",
              state: errorColor,
              setter: setErrorColor,
              key: "errorColor",
            },
          ].map(({ label, state, setter, key }) => (
            <div key={key} className="color-picker-container">
              <FormLabel>{label}</FormLabel>
              <ColorPickerWrapper>
                <div
                  className="color-swatch"
                  style={{ backgroundColor: state, border: "1px solid #ccc" }}
                  onClick={() => handleColorClick(key)}
                />
                {displayColorPicker[key] && (
                  <div className="popover">
                    <div
                      className="cover"
                      onClick={() => handleColorClose(key)}
                    />
                    <SketchPicker
                      color={state}
                      onChange={(color) => setter(color.hex)}
                      className="sketch-picker"
                    />
                  </div>
                )}
              </ColorPickerWrapper>
            </div>
          ))}
        </ColorSection>
      </section>

      <section className="font-size-settings-section">
        <h4>Font Size and Spacing Settings</h4>
        {[
          {
            label: "Title Size",
            value: titleSize,
            setter: setTitleSize,
          },
          {
            label: "Subtitle Size",
            value: subtitleSize,
            setter: setSubtitleSize,
          },
        ].map(({ label, value, setter }) => (
          <FormControl key={label} fullWidth margin="normal">
            <InputLabel>{label}</InputLabel>
            <Select
              value={value}
              onChange={(e) => setter(e.target.value)}
              label={label}
            >
              {[
                "12px",
                "14px",
                "16px",
                "18px",
                "20px",
                "24px",
                "28px",
                "32px",
                "36px",
                "40px",
              ].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </section>

      <section className="spacing-settings-section">
        <h4>Card Settings</h4>
        {[
          {
            label: "Card Padding",
            value: cardPadding,
            setter: setCardPadding,
          },
          {
            label: "Card Border Radius",
            value: cardBorderRadius,
            setter: setCardBorderRadius,
          },
          {
            label: "Form Gap",
            value: formGap,
            setter: setFormGap,
          },
        ].map(({ label, value, setter }) => (
          <FormControl key={label} fullWidth margin="normal">
            <InputLabel>{label}</InputLabel>
            <Select
              value={value}
              onChange={(e) => setter(e.target.value)}
              label={label}
            >
              {[
                "0px",
                "4px",
                "8px",
                "12px",
                "16px",
                "20px",
                "24px",
                "28px",
                "32px",
                "36px",
                "40px",
                "44px",
                "48px",
              ].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </section>

      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
        disabled={apiLoading}
      >
        Save Direct Link Styles
      </Button>
    </Box>
  );
};

export default DirectLinkStyles;
