// EventSessionStyles/WaitingRoomStyles.tsx
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SketchPicker } from "react-color";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEventSession } from "../../providers/eventSessions";
import { ColorPickerWrapper, ColorSection } from "./styles";
import { AiOutlineLink } from "react-icons/ai";
import toast from "react-hot-toast";
import { useUser } from "../../providers/users";

interface WaitingRoomProps {
  eventSession: any;
  setEventSession: (session: any) => void;
}

const WaitingRoomStyles: React.FC<WaitingRoomProps> = ({
  eventSession,
  setEventSession,
}) => {
  const { t } = useTranslation();
  const { editEventSession } = useEventSession();
  const { apiLoading } = useUser();
  // Color states
  const [mainBackground, setMainBackground] = useState(
    "linear-gradient(90deg, rgba(4, 2, 2, 1) 0%, rgba(0, 0, 0, 1) 35%, rgba(29, 29, 29, 1) 100%)"
  );
  const [timerBackground, setTimerBackground] = useState("#4a4949");
  const [titleColor, setTitleColor] = useState("#ffffff");
  const [timerTextColor, setTimerTextColor] = useState("#ffffff");
  const [unitBackground, setUnitBackground] = useState("rgba(0, 0, 0, 0.2)");

  // Font size states
  const [titleSize, setTitleSize] = useState("32px");
  const [subtitleSize, setSubtitleSize] = useState("25px");
  const [timerNumbersSize, setTimerNumbersSize] = useState("24px");
  const [timerLabelsSize, setTimerLabelsSize] = useState("16px");

  // Image and Spacing states
  const [imageWidth, setImageWidth] = useState(400);
  const [imageHeight, setImageHeight] = useState(300);
  const [imagePadding, setImagePadding] = useState("20px");
  const [titlePadding, setTitlePadding] = useState("20px");
  const [containerPadding, setContainerPadding] = useState("0px");
  const [timerGap, setTimerGap] = useState("15px");
  const [file, setFile] = useState<File | null>(null);

  // Color picker display states
  const [displayColorPicker, setDisplayColorPicker] = useState<
    Record<string, boolean>
  >({
    mainBackground: false,
    timerBackground: false,
    titleColor: false,
    timerTextColor: false,
    unitBackground: false,
  });

  useEffect(() => {
    if (eventSession?.waitingRoom?.styles) {
      const styles = eventSession.waitingRoom.styles;
      setMainBackground(styles.mainBackground || mainBackground);
      setTimerBackground(styles.timerBackground || timerBackground);
      setTitleColor(styles.titleColor || titleColor);
      setTimerTextColor(styles.timerTextColor || timerTextColor);
      setUnitBackground(styles.unitBackground || unitBackground);
      setTitleSize(styles.titleSize || titleSize);
      setSubtitleSize(styles.subtitleSize || subtitleSize);
      setTimerNumbersSize(styles.timerNumbersSize || timerNumbersSize);
      setTimerLabelsSize(styles.timerLabelsSize || timerLabelsSize);
      setImageWidth(styles.imageWidth || imageWidth);
      setImageHeight(styles.imageHeight || imageHeight);
      setImagePadding(styles.imagePadding || imagePadding);
      setTitlePadding(styles.titlePadding || titlePadding);
      setContainerPadding(styles.containerPadding || containerPadding);
      setTimerGap(styles.timerGap || timerGap);
    }
  }, [eventSession]);

  const formSchema = yup
    .object()
    .shape({
      centerImage: yup.string().url("Must be a valid URL"),
      labelDays: yup.string(),
      labelHours: yup.string(),
      labelMinutes: yup.string(),
      labelSeconds: yup.string(),
      fileInput: yup
        .mixed()
        .test("fileSize", t("file_size"), (value) => {
          if (!value || !value[0]) return true;
          return value[0].size <= 1000 * 1024 * 1024;
        })
        .test(
          "type",
          t("file_accept", { file_types: ".png, .jpg, .jpeg, .gif" }),
          (value) => {
            if (!value || !value[0]) return true;
            return (
              value[0].type === "image/jpg" ||
              value[0].type === "image/jpeg" ||
              value[0].type === "image/gif" ||
              value[0].type === "image/png"
            );
          }
        ),
    })
    .required();

  type FormValues = yup.InferType<typeof formSchema>;

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    setFile(file);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });

  const handleColorClick = (colorType: string) => {
    setDisplayColorPicker({
      ...displayColorPicker,
      [colorType]: !displayColorPicker[colorType],
    });
  };

  const handleColorClose = (colorType: string) => {
    setDisplayColorPicker({
      ...displayColorPicker,
      [colorType]: false,
    });
  };

  const onSave = async (data: FormValues) => {
    try {
      setFile(null);
      const waitingRoomConfig = {
        styles: {
          mainBackground,
          timerBackground,
          titleColor,
          titleSize,
          subtitleSize,
          timerNumbersSize,
          timerLabelsSize,
          timerTextColor,
          unitBackground,
          imageWidth,
          imageHeight,
          imagePadding,
          titlePadding,
          containerPadding,
          timerGap,
          centerImage: data.centerImage,
        },
        labelDays: data.labelDays,
        labelHours: data.labelHours,
        labelMinutes: data.labelMinutes,
        labelSeconds: data.labelSeconds,
      };

      const response = await editEventSession(
        eventSession.id,
        {
          fileInput: data.fileInput,
          waitingRoom: waitingRoomConfig,
        },
        true
      );

      setEventSession(response);
      toast.success("Waiting room styles saved successfully");
    } catch (error) {
      toast.error("Failed to save waiting room styles");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSave)} sx={{ mt: 2 }}>
      <section className="basic-info-section">
        <h4>Labels</h4>
        <TextField
          error={!!errors.labelDays}
          helperText={errors.labelDays?.message?.toString()}
          label="Days Label"
          variant="filled"
          defaultValue={eventSession?.waitingRoom?.labelDays || "Days"}
          sx={{ mr: 2, mb: 2 }}
          {...register("labelDays")}
        />
        <TextField
          error={!!errors.labelHours}
          helperText={errors.labelHours?.message?.toString()}
          label="Hours Label"
          variant="filled"
          defaultValue={eventSession?.waitingRoom?.labelHours || "Hours"}
          sx={{ mr: 2, mb: 2 }}
          {...register("labelHours")}
        />
        <TextField
          error={!!errors.labelMinutes}
          helperText={errors.labelMinutes?.message?.toString()}
          label="Minutes Label"
          variant="filled"
          defaultValue={eventSession?.waitingRoom?.labelMinutes || "Minutes"}
          sx={{ mr: 2, mb: 2 }}
          {...register("labelMinutes")}
        />
        <TextField
          error={!!errors.labelSeconds}
          helperText={errors.labelSeconds?.message?.toString()}
          label="Seconds Label"
          variant="filled"
          defaultValue={eventSession?.waitingRoom?.labelSeconds || "Seconds"}
          sx={{ mr: 2, mb: 2 }}
          {...register("labelSeconds")}
        />
        <TextField
          error={!!errors.centerImage}
          helperText={errors.centerImage?.message?.toString()}
          label="Center Image URL"
          variant="filled"
          defaultValue={eventSession?.waitingRoom?.styles?.centerImage}
          sx={{ mr: 2, mb: 2 }}
          {...register("centerImage")}
        />
      </section>

      <section className="image-settings-section">
        <h4>Image Settings</h4>

        <Button
          component="label"
          variant="outlined"
          startIcon={<AiOutlineLink />}
        >
          Upload Image
          <input
            {...register("fileInput")}
            className="inputNone"
            type="file"
            accept=".png, .jpg, .jpeg, .gif"
            onInput={handleFileUpload}
          />
        </Button>
        <p className="errosForm">
          {errors.fileInput && errors.fileInput.message?.toString()}
        </p>
        <Box>{file?.name}</Box>

        <FormControl fullWidth margin="normal">
          <InputLabel>Image Width (px)</InputLabel>
          <Select
            value={imageWidth}
            onChange={(e) => setImageWidth(Number(e.target.value))}
            label="Image Width (px)"
          >
            {[200, 300, 400, 500, 600, 700, 800].map((size) => (
              <MenuItem key={size} value={size}>
                {size}px
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Image Height (px)</InputLabel>
          <Select
            value={imageHeight}
            onChange={(e) => setImageHeight(Number(e.target.value))}
            label="Image Height (px)"
          >
            {[150, 200, 250, 300, 350, 400, 450, 500].map((size) => (
              <MenuItem key={size} value={size}>
                {size}px
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Image Padding (px)</InputLabel>
          <Select
            value={imagePadding}
            onChange={(e) => setImagePadding(e.target.value)}
            label="Image Padding (px)"
          >
            {[
              "0px",
              "10px",
              "15px",
              "20px",
              "25px",
              "30px",
              "35px",
              "40px",
              "45px",
              "50px",
            ].map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </section>

      <section className="color-settings-section">
        <h4>Color Settings</h4>
        <ColorSection>
          {[
            {
              label: "Main Background",
              state: mainBackground,
              setter: setMainBackground,
              key: "mainBackground",
            },
            {
              label: "Timer Background",
              state: timerBackground,
              setter: setTimerBackground,
              key: "timerBackground",
            },
            {
              label: "Title Color",
              state: titleColor,
              setter: setTitleColor,
              key: "titleColor",
            },
            {
              label: "Timer Text Color",
              state: timerTextColor,
              setter: setTimerTextColor,
              key: "timerTextColor",
            },
            {
              label: "Unit Background",
              state: unitBackground,
              setter: setUnitBackground,
              key: "unitBackground",
            },
          ].map(({ label, state, setter, key }) => (
            <div key={key} className="color-picker-container">
              <FormLabel>{label}</FormLabel>
              <ColorPickerWrapper>
                <div
                  className="color-swatch"
                  style={{ backgroundColor: state, border: "1px solid #ccc" }}
                  onClick={() => handleColorClick(key)}
                />
                {displayColorPicker[key] && (
                  <div className="popover">
                    <div
                      className="cover"
                      onClick={() => handleColorClose(key)}
                    />
                    <SketchPicker
                      color={state}
                      onChange={(color) => setter(color.hex)}
                      className="sketch-picker"
                    />
                  </div>
                )}
              </ColorPickerWrapper>
            </div>
          ))}
        </ColorSection>
      </section>

      <section className="font-size-settings-section">
        <h4>Font Size Settings</h4>
        {[
          {
            label: "Title Size",
            value: titleSize,
            setter: setTitleSize,
          },
          {
            label: "Subtitle Size",
            value: subtitleSize,
            setter: setSubtitleSize,
          },
          {
            label: "Timer Numbers Size",
            value: timerNumbersSize,
            setter: setTimerNumbersSize,
          },
          {
            label: "Timer Labels Size",
            value: timerLabelsSize,
            setter: setTimerLabelsSize,
          },
        ].map(({ label, value, setter }) => (
          <FormControl key={label} fullWidth margin="normal">
            <InputLabel>{label}</InputLabel>
            <Select
              value={value}
              onChange={(e) => setter(e.target.value)}
              label={label}
            >
              {[
                "12px",
                "14px",
                "16px",
                "18px",
                "20px",
                "24px",
                "28px",
                "32px",
                "36px",
                "40px",
              ].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </section>

      <section className="spacing-settings-section">
        <h4>Spacing Settings</h4>
        {[
          {
            label: "Container Padding",
            value: containerPadding,
            setter: setContainerPadding,
          },
          {
            label: "Timer Gap",
            value: timerGap,
            setter: setTimerGap,
          },
          {
            label: "Title Padding",
            value: titlePadding,
            setter: setTitlePadding,
          },
        ].map(({ label, value, setter }) => (
          <FormControl key={label} fullWidth margin="normal">
            <InputLabel>{label}</InputLabel>
            <Select
              value={value}
              onChange={(e) => setter(e.target.value)}
              label={label}
            >
              {[
                "0px",
                "10px",
                "15px",
                "20px",
                "25px",
                "30px",
                "35px",
                "40px",
                "45px",
                "50px",
              ].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </section>

      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
        disabled={apiLoading}
      >
        Save Waiting room Styles
      </Button>
    </Box>
  );
};

export default WaitingRoomStyles;
